<template>
  <div
    
  >
    <div>
      <small v-html="texto"> </small>
    </div>
    <b-btn v-bind="$attrs" @click="$emit('click')" size="sm" variant="primary" class="rounded">
      <b-icon icon="credit-card"></b-icon> Clique aqui para visualiza{{qtdVencidos>1?'-las':'-la'}}
    </b-btn>
  </div>
</template>

<script>
export default {
  props:{
    qtdVencidos: Number
  },
  computed: {
    texto(){
      return `Há <b>${this.qtdVencidos}</b> fideliza${this.qtdVencidos > 1?'ções vencidas':'ção vencida'}`
    }
  }
};
</script>

<style></style>
